<template>
  <div ref="dropdown" class="dropdown new">
    <span v-if="simple" :class="classObject" class="material-symbols-outlined title c-pointer"
          @click="toggle">
      more_vert
    </span>
    <ButtonComponent v-else :variant="variant" @click="toggle">
      <slot name="button"/>
      <span v-if="!noCaret" class="material-symbols-outlined caret">
        arrow_drop_down
      </span>
    </ButtonComponent>
    <div :class="menuClass" class="dropdown-menu">
      <header v-if="header" class="dropdown-header">
        {{ header }}
      </header>
      <slot/>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'DropdownComponent',
  components: {
    ButtonComponent,
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    header: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    menuSize: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'button',
    },
    noCaret: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowing: false,
    };
  },
  computed: {
    classObject() {
      return [
        this.variant,
      ];
    },
    menuClass() {
      return [
        { show: this.isShowing },
        { 'dropdown-menu-right': this.right },
        this.menuSize,
      ];
    },
    visible() {
      return this.$refs.current?.visible ?? false;
    },
  },
  created() {
  },
  methods: {
    toggle() {
      if (this.isShowing) {
        this.hide();
      } else {
        this.show();
      }
    },
    show() {
      this.isShowing = true;
      window.addEventListener('click', this.clickOutside);
    },
    hide() {
      this.isShowing = false;
      window.removeEventListener('click', this.clickOutside);
    },
    clickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown.new {
  display: inline-flex;
  .dropdown-menu {
    inset: 0px 0px auto auto;
  }
}

.btn {
  &.light {
    background-color: #fafafa;
    border-color: #fafafa;
    color: #212529;

    &:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
      box-shadow: 0 5px 10px rgba(250, 250, 250, 0.4);
    }
  }
}

.dropdown {
  .dropdown-menu {
    overflow-x: auto;
    max-height: 50vh;
    &.dropdown-menu-right {
      right: 0 !important;
      left: auto !important;
    }

    &.dropdown-menu-left {
      right: auto !important;
      left: 0 !important;
    }

    &.lg {
      min-width: 500px;
    }

    .form-group {
      padding: 0 15px;
    }

    .btn {
      margin: 0 15px;
    }
  }

  .link {
    padding-right: 1rem;
    margin-top: -2px;
  }

  .caret {
    margin-right: -20px;
  }
}

buttoncomponent {
  padding: 20px;
  cursor: pointer;
}
</style>
