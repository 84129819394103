<template>
  <div class="content">
    <PageHeader title="Chat" :subtitle="$t('sms.usage-report.header')"/>
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <div class="full">
            <div class="row">
              <div class="col-sm-8">
                <ul class="nav nav-tabs">
                  <li class="nav-item" role="presentation">
                    <router-link
                      to="/chat/reports/stat"
                      class="nav-link"
                      replace
                    >
                      <span class="material-symbols-outlined"
                      >query_stats</span
                      >
                      {{ $tc('app.stats', 2) }}
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      to="/chat/reports/history"
                      class="nav-link"
                      replace
                    >
                      <span class="material-symbols-outlined">history</span>
                      {{ $t('app.history') }}
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      to="/chat/reports/exports"
                      class="nav-link"
                      replace
                    ><span class="material-symbols-outlined">
                        cloud_download </span
                    >{{ $tc('app.file', 2) }}
                    </router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-sm-4">
                <ul
                  class="nav-actions d-flex flex-row-reverse align-items-center"
                >
                  <li v-if="isChatRoom">
                    <DropdownComponent variant="light" right>
                      <template #button>
                        <span class="material-icons-outlined title">more_vert</span>
                      </template>
                      <a class="dropdown-item" href="javascript:void(0)" @click="showContact">Dados
                        do Contato</a>
                    </DropdownComponent>
                  </li>
                  <li>
                    <b-dropdown variant="light" :right="true" no-caret>
                      <template #button-content>
                        <span class="material-icons-outlined title"
                        >file_download</span
                        >
                      </template>
                      <b-dropdown-item @click="exportData('csv')">
                        CSV
                      </b-dropdown-item>
                      <template v-if="isChatStats">
                        <b-dropdown-item @click="exportData('png')">
                          PNG
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportData('svg')">
                          SVG
                        </b-dropdown-item>
                      </template>
                      <template v-else>
                        <b-dropdown-item @click="exportData('pdf')">
                          PDF
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </li>
                  <li>
                    <button class="btn btn-light" @click="reload()">
                      <span class="material-icons-outlined title">refresh</span>
                    </button>
                  </li>
                  <li v-if="!isChatRoom">
                    <button class="btn btn-light calendar">
                      <span class="material-icons-outlined title"
                      >calendar_today</span
                      >
                      <date-range-picker
                        class="form-control"
                        :format="'YYYY-MM-DD'"
                        :options="options"
                        @change="changeDate"
                      />
                    </button>
                  </li>
                  <li v-if="!isChatRoom">
                    <button class="btn btn-light" @click="showFilter()">
                      <span class="material-icons-outlined title"
                      >filter_list</span
                      >
                    </button>
                  </li>
                  <li v-if="isChatRoom">
                    <DropdownComponent variant="light" right menu-size="lg"
                                       header="Compartilhar Conversa">
                      <template #button>
                        <span class="material-icons-outlined title">share</span>
                      </template>
                      <InputComponent :value="currentUrl" disabled></InputComponent>
                      <ButtonComponent @click="copyLink">
                        Copiar Link
                        <span class="material-symbols-outlined">link</span>
                      </ButtonComponent>
                    </DropdownComponent>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el">
          <router-view
            ref="view"
            :filters="form.filters"
            :start-date="form.date.gte"
            :end-date="form.date.lte"
          />
        </div>
      </div>
    </div>
    <SidebarComponent ref="filter" title="Filtros">
      <InputComponent :name="$t('app.protocol')" type="text" :label="$tc('app.protocol', 1)"
                      placeholder="F9999999999999999999" v-model="filter.protocol"></InputComponent>
      <InputComponent :name="$t('app.recipient')" type="text" :label="$tc('app.recipient', 1)"
                      placeholder="5511999999999" v-model="filter.phone"></InputComponent>
      <SelectComponent v-model="filter.agentId" :label="$tc('app.agent', 1)" :options="agents"
                       text-field="name"
                       value-field="id"></SelectComponent>
      <SelectComponent v-model="filter.channels" :label="$tc('app.channel', 1)" :options="channels"
                       text-field="description"
                       value-field="value" multiple></SelectComponent>
      <SelectComponent v-model="filter.tabs" :label="$tc('app.tab', 1)" :options="tabs"
                       text-field="name"
                       value-field="id" multiple></SelectComponent>
      <SelectComponent v-model="filter.tags" label="Tags" :options="tags" text-field="name"
                       value-field="id" multiple></SelectComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent @click="cleanFilter" variant="secondary">Limpar</ButtonComponent>
          <ButtonComponent @click="applyFilter">Aplicar Filtros</ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';

export default {
  name: 'OmniReports',
  components: {
    SelectComponent,
    SidebarComponent,
    ButtonComponent,
    InputComponent,
    // Card,
    PageHeader,
    DropdownComponent,
  },
  data() {
    return {
      agents: [],
      tags: [],
      channels: [
        { value: 'whatsapp', description: 'Whatsapp' },
        { value: 'whatsapp-java', description: 'Whatsapp' },
        { value: 'widget-java', description: 'WebChat' },
        { value: 'messenger', description: 'Facebook Messenger' },
        { value: 'rcs', description: 'RCS' },
        { value: 'telegram', description: 'Telegram' },
        { value: 'instagram', description: 'Instagram Direct' },
        { value: 'email', description: 'E-mail' },
        { value: 'google-business', description: 'Google Business' },
      ],
      filter: {
        phone: '',
        channels: [],
        tabs: [],
        tags: [],
        agentId: '',
        protocol: '',
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          agentId: '',
          channels: [],
          tabs: [],
          tags: [],
          protocol: '',
        },
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  computed: {
    tabs() {
      return this.$live?.tabs || [];
    },
    isChatRoom() {
      return this.$route.name === 'ChatRoom';
    },
    isChatStats() {
      return this.$route.name === 'ChatStats';
    },
    currentUrl() {
      return window.location.href;
    },
  },
  created() {
    this.fetchAgents();
    this.fetchTags();
  },
  // Pai
  methods: {
    fetchAgents() {
      this.$store.dispatch('fetchOmniAgents').then((response) => {
        this.agents = response;
      });
    },
    fetchTags() {
      this.$store.dispatch('fetchOmniTags').then((response) => {
        this.tags = response;
      });
    },
    showFilter() {
      this.$refs.filter.toggle();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$refs.filter.hide();
      this.$nextTick(() => this.reload());
    },
    cleanFilter() {
      this.filter = {
        agentId: '',
        channels: [],
        tabs: [],
        tags: [],
        protocol: '',
      };
    },
    reload() {
      this.$refs.view.fetch();
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
    copyLink() {
      navigator.clipboard.writeText(this.currentUrl).then(() => {
        this.$toast.show({
          title: 'Link Copiado',
          type: 'success',
        });
      });
    },
    showContact() {
      this.$refs.view.showContact();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}

.items {
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;

  &.last {
    margin: 0;
  }
}

::v-deep.nav-actions {
  list-style-type: none;
  margin: 0;

  & > li {
    padding: 0.5rem;

    &:first-child {
      padding-right: 0;
    }

    & {
      .btn.light, .btn.btn-light {
        position: relative;
        font-size: 1.5rem;
        padding: 0.5rem;

        &.calendar {
          input {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
      }

      .dropdown-menu {
        left: 0 !important;
      }
    }
  }
}

/* Rebranding */
.card.card-tabs .card-header {
  & > .full {
    width: 100%;
    padding: 0 1rem;
    gap: 2.5rem;
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(210, 221, 234, 0.5);
  }

  .nav.nav-tabs {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
  gap: 1.32rem;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

.btn {
  &.active {
    background: #00ed7c;
  }
}
</style>
